import WebServiceRequest from '../Api/WebServiceRequest'

class CreateProduct extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Product/CreateProduct')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class UpdateProduct extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('Product/UpdateProduct')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class GetProductsByGameServiceId extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Product/GetProductsByGameServiceId')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class DeleteProduct extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag('Product/DeleteProduct')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class GetProductDetail extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Product/GetProductByIdV2')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

export {
  CreateProduct,
  GetProductsByGameServiceId,
  DeleteProduct,
  GetProductDetail,
  UpdateProduct
}
