<template>
  <b-overlay :show="showOverlay">
    <b-col class="mb-1 bg-white p-0" cols="12">
      <DynamicBreadCrumb :items="pageBreadcrumb"></DynamicBreadCrumb>
    </b-col>
    <validation-observer ref="createProduct" tag="div">
      <b-card class="data-edit-wrapper">
        <h2>Game Service Product</h2>
        <b-form class="mt-2">
          <b-row>
            <b-col class="" md="4">
              <b-form-group
                  label="Title"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="data.name"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-row>
                <b-col v-for="(item,idx) in currencies" cols="12" md="3">
                  <b-form-group
                      :label="`Price In ${item.name}`"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        :ref="`price${idx}`"
                        type="number"
                    />

                  </b-form-group>
                </b-col>
              </b-row>

            </b-col>
            <b-col cols="3">
              <b-button
                  class=" "
                  variant="primary"
                  @click="createProduct"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </validation-observer>

  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BOverlay, BRow, } from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import { GetAllCurrencies } from '@/libs/Api/Currencies'
import { CreateProduct } from '@/libs/Api/product'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'

export default {
  components: {
    DynamicBreadCrumb,
    MediaHandler,
    BForm,
    ValidationProvider,
    ckEditorMain,
    BCard,
    ValidationObserver,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BOverlay,
    vSelect,
  },
  name: 'create-product',
  title: 'create product',
  data() {
    return {
      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      pageBreadcrumb: [
        {
          text: 'Manage Games',
          href: '/apps/games/manage-games',
          active: false
        },
        {
          text: `Manage Game`,
          href: `/apps/games/manage-game?gameId=${this.$route.query.gameId}`,
          active: false
        },
        {
          text: `Manage Game Service Category`,
          href: `/apps/games/game-service-categories/manage-game-service-category?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}`,
          active: false
        },
        {
          text: `Manage Game Service`,
          href: `/apps/games/game-service-categories/game-service/manage-game-services?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}`,
          active: false
        },
        {
          text: `Create Product`,
          href: `/apps/games/game-service-categories/game-service/products/create-product?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}`,
          active: true
        },
      ],
      currencies: null,
      data: {
        name: '',
        gameServiceId: this.$route.query.gameServiceId,
        productPrice: [],
      },
    }
  },

  async created() {
    await Promise.all([
      this.getAllCurrencies()
    ])
  },
  methods: {
    async getAllCurrencies() {
      let _this = this
      _this.showOverlay = true
      let getAllCurrencies = new GetAllCurrencies(_this)
      let data = {
        pageNumber: 1,
        count: 20,
      }
      getAllCurrencies.setParams(data)
      await getAllCurrencies.fetch(function (content) {
        _this.currencies = content.data.getCurrencies
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    createPayload() {
      this.currencies.forEach((item, idx) => {
        if(this.$refs[`price${idx}`][0]?.$el.value){
        this.data.productPrice.push({
          currencyId: item.id,
          priceValue: this.$refs[`price${idx}`][0]?.$el.value
        })
        }
      })
      return this.data
    },
    async createProduct() {
      let _this = this
      Helper.validateForm(_this, 'createProduct', async () => {
        _this.showOverlay = true
        let createProduct = new CreateProduct(_this)
        let data = this.createPayload()
        createProduct.setParams(data)
        await createProduct.fetch(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.$router.push(`/apps/games/game-service-categories/game-service/manage-game-services?gameId=${_this.$route.query.gameId}&gameServiceCategoryId=${_this.$route.query.gameServiceCategoryId}&gameServiceId=${_this.$route.query.gameServiceId}`)
          } else {
            this.data = {
              name: '',
              gameServiceId: this.$route.query.gameServiceId,
              productPrice: [],
            }
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    },

  }
}
</script>

<style scoped>

</style>
